.header { 
    height: 150px;
    top: 0; 
    margin: auto;
    position: fixed;
    width: 80%;
    max-width: 1680px;
    background-color: var(--main-bg-color);
    padding: 40px; 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 10;

    h1 {
        font-family: 'MerriweatherSans-Regular';
        font-size: 20px;
        color: #818181;
    }

    h1 span {
        color: var(--main-color);
    }

    .contact {
        width: 30%;
    }
    .contact a {
        color: var(--main-color);
        font-size: 25px;   
    }

    .contact ul {
    display: flex;
    flex-direction:row;
    justify-content: space-around;
    }

    .logo h1:hover{
        transform: scale(1.1); 
    }

    .contact ul li:hover {
        transform: scale(1.3); 
    }
}

@media only screen and (max-width:767px) {
    .header {
        height: 120px;}
    }

@media only screen and (max-width: 914px){
    .header {
        margin:0;
        
        .contact {
            padding-right: 15px;
        }

        .contact ul li{
            padding: 0 10px;
        }  
    }
}

@media only screen and (min-width: 480px) and (max-width: 914px) {
    .header{
        width: calc(100% - 140px);
    }
}
