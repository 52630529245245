.impressum {
    .impwrapper{
        background-color: var(--main-bg-color);
        margin-top: 150px;
        border-radius: 3px;
        padding: 30px 80px;
        height: calc(100vh - 380px);

        p, h2, h3 {
            color: var(--text-grey-color);
            background-color: var(--main-bg-color);
            margin: 1%;
            border-radius: 3px;
            text-align: left;
            font-size: 20px;
        } 

        h2, h3 {
            font-weight: bold;
        }
    }
}

@media only screen and (max-width: 914px){
    .impressum {
        .impwrapper{
            margin-top: 120px; 
            margin-bottom: 30px;
            padding: 0;
            height: 300px;
        }    
    }
}