:root {
  --main-color: #f31486;
  --main-bg-color: black;
  --text-grey-color: #818181;
}

@font-face {
  font-family: 'Merriweather Sans';
  src: local('Merriweather'), url('./fonts/MerriweatherSans-Italic.woff2') format('woff2');
  src: local('Merriweather'), url('./fonts/MerriweatherSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Merriweather Sans';
  src: local('Merriweather'), url('./fonts/MerriweatherSans-Regular.woff2') format('woff2');
  src: local('Merriweather'),url('./fonts/MerriweatherSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.App {
  text-align: center;

  .wrapper 
    {
    width: 80%;
    max-width: 1680px;
    margin: auto;
  }
  
  .wrapper.about .header, 
  .wrapper.about .footer {
    background-color: rgb(1 1 1 / 86%);
  }

  &::after {
      position: fixed;
      content: "";
      background-color: black;
      width: 80%;
      height: 100%;
      top: 0;
      left: 10%;
      z-index: -1;
      max-width: 1680px;
    }
  }


@media only screen and (max-width: 914px){
  .App {
    text-align: center;

    .wrapper {
      width: 100%;
      margin:0;
      padding: 0 30px;
  
      .header, .footer {
        width: calc(100% - 60px);
        padding:0;
      }
    }
  }
}

@media only screen and (min-width: 480px){
  .App .wrapper{
    padding: 0 40px;
  }

}

@media only screen and (min-width: 915px){
  .App .wrapper{
    padding: 0;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
