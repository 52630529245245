.footer {
    bottom: 0;  
    min-height: 230px;
    position: fixed;
    background-color: var(--main-bg-color);
    width: 80%;
    max-width: 1680px;
    
    a.active { 
        h2 {
            text-decoration: underline;
        }
    }

    h2 {
        color: var(--main-color);
        font-size: 30px;
        
        &:hover {
            transform: scale(1.1);
        }
    }

    .home {
        padding-top: 2%;
    }
    
    .link {
        padding-bottom: 30px;
    }
}


@media only screen and (max-width: 914px){
    .footer {
        margin: auto;
        position: relative;
    }
}