 .main {
    margin-top: 150px;
    height: 100vh;
    display: flex;
    background-color: var(--main-bg-color);
    flex-direction: column;

    .titles {
        color: #818181;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin:0; 
        
        .webDev {
            font-size: 70px;
            margin-left: 100px;
        }
    
        .frontend  {
            font-size: 50px;
            margin: 10px;
            margin-left: 400px; 
        }
    
        .junior {
            color: var(--main-color);
            font-size: 30px;
            margin: 40px 0 0 130px;
        }
    }

    .animatedSection {
        display: flex;
        width: 15%;
        flex-direction: row;
        align-items: center; 
        position: relative;
        left: 15%;

        p, .brain, .heart {
        padding: 5px;
        }

        p {
            color: var(--text-grey-color);
        }
    }
}

@media only screen and (max-width: 914px){
    .main {
        width: 100%;
        height: 350px;
        padding-top: 20px;
        

        .titles {
            width: 100%;

            .webDev {
                font-size: 30px;
                margin: 0;
            }

            .junior {
                margin: 0;
                font-size: 25px;
                font-weight: bold;
                padding: 2px 0 5px;
            }

            .frontend  {
                font-size: 30px;
                margin: 0;
                padding: 2px 0 8px;
            }   
        }

        .animatedSection {
            left:0;
            width:100%;   
        }
    }
}

@media only screen and (min-width:768px) and (max-width: 914px) {
    .main {
        height: 330px;
        margin-top: 120px;
    }
}
     
    

    
    
